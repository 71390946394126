import { Injectable } from '@angular/core';
import { EquipmentDetailsResponse } from '@api/equipment/responses/equipment-details.response';
import { Equipment } from '@features/equipment/models/equipment';
import { EquipmentCategory } from '@features/equipment/models/equipment-category';

@Injectable({
  providedIn: 'root',
})
export class EquipmentDetailsConverter {
  fromResponse(response: EquipmentDetailsResponse): Equipment {
    return new Equipment(
      response.id,
      response.name,
      new EquipmentCategory(
        response.category.id,
        response.category.name,
        response.category.createdAt
      ),
      response.productionYear,
      response.purchasedAt,
      response.manufacturer,
      response.serialNumber
    );
  }
}
