import { Id } from '@shared/types/id';
import { Nillable } from '@core/utils/nil/nillable';
import { EquipmentCategory } from '@features/equipment/models/equipment-category';

export class Equipment {
  constructor(
    readonly id: Id,
    readonly name: string,
    readonly category: EquipmentCategory,
    readonly productionYear: Nillable<Date>,
    readonly purchasedAt: Nillable<Date>,
    readonly manufacturer: Nillable<string>,
    readonly serialNumber: Nillable<string>
  ) {}

  get categoryId(): Id {
    return this.category.id;
  }

  get categoryName(): string {
    return this.category.name;
  }
}
