import { jsonMember, jsonObject } from 'typedjson';
import { Nillable } from '@core/utils/nil/nillable';
import { valueOrUndefined } from '@core/utils/nil/value-or-undefined';
import { dateSerializer } from '@api/common/json/date-serializer';
import { Id } from '@shared/types/id';
import { YYYY, YYYY_MM_DD } from '@core/locale/consts/date-format';

@jsonObject
export class EquipmentCreateRequest {
  @jsonMember name!: string;
  @jsonMember({ name: 'categoryId' }) category!: Id;
  @jsonMember serialNumber?: string;
  @jsonMember manufacturer?: string;
  @jsonMember({ serializer: dateSerializer(YYYY) }) productionYear?: Date;
  @jsonMember({ serializer: dateSerializer(YYYY_MM_DD) }) purchasedAt?: Date;

  constructor(
    name: string,
    category: Id,
    serialNumber?: Nillable<string>,
    manufacturer?: Nillable<string>,
    productionYear?: Nillable<Date>,
    purchasedAt?: Nillable<Date>
  ) {
    this.name = name;
    this.category = category;
    this.serialNumber = valueOrUndefined(serialNumber);
    this.manufacturer = valueOrUndefined(manufacturer);
    this.productionYear = valueOrUndefined(productionYear);
    this.purchasedAt = valueOrUndefined(purchasedAt);
  }
}
