import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { Id } from '@shared/types/id';
import { CreateResourceResponse } from '@api/common/responses/create-recource.response';
import { EquipmentUpsertFormData } from '@features/equipment/components/equipment-upsert-form/models/equipment-upsert-form-data';
import { ToastService } from '@shared/toast/services/toast.service';
import { EquipmentCreateRequest } from '@api/equipment/requests/equipment-create.request';
import { EquipmentUpdateRequest } from '@api/equipment/requests/equipment-update.request';
import { Equipment } from '@features/equipment/models/equipment';
import { EquipmentDetailsConverter } from '@features/equipment/converters/equipment-details.converter';
import { EquipmentApiService } from '@api/equipment/equipment-api.service';

@Injectable({
  providedIn: 'root',
})
export class EquipmentActionService {
  constructor(
    private readonly equipmentApi: EquipmentApiService,
    private readonly detailsConverter: EquipmentDetailsConverter,
    private readonly toast: ToastService
  ) {}

  fetch(id: Id): Observable<Equipment> {
    return this.equipmentApi
      .details(id)
      .pipe(map(response => this.detailsConverter.fromResponse(response)));
  }

  create(data: EquipmentUpsertFormData): Observable<CreateResourceResponse> {
    const request = new EquipmentCreateRequest(
      data.name,
      data.category,
      data.serialNumber,
      data.manufacturer,
      data.productionYear,
      data.purchasedAt
    );

    return this.equipmentApi.create(request);
  }

  update(id: Id, data: EquipmentUpsertFormData): Observable<void> {
    const request = new EquipmentUpdateRequest(
      data.name,
      data.category,
      data.serialNumber,
      data.manufacturer,
      data.productionYear,
      data.purchasedAt
    );

    return this.equipmentApi.update(id, request);
  }

  createByForm(
    data: EquipmentUpsertFormData
  ): Observable<CreateResourceResponse> {
    return this.create(data).pipe(
      tap({
        next: () =>
          this.toast.show(builder =>
            builder
              .initSuccess('Sprzęt labolatoryjny został dodany pomyślnie.')
              .build()
          ),
        error: () =>
          this.toast.show(builder =>
            builder
              .initError('Nie udało się dodać sprzętu labolatoryjnego.')
              .build()
          ),
      })
    );
  }

  updateByForm(id: Id, data: EquipmentUpsertFormData): Observable<void> {
    return this.update(id, data).pipe(
      tap({
        next: () =>
          this.toast.show(builder =>
            builder
              .initSuccess(
                'Sprzęt labolatoryjny został zaktualizowany pomyślnie.'
              )
              .build()
          ),
        error: () =>
          this.toast.show(builder =>
            builder
              .initError('Nie udało się zaktualizować sprzętu labolatoryjnego.')
              .build()
          ),
      })
    );
  }
}
