import { jsonMember, jsonObject } from 'typedjson';
import { Id } from '@shared/types/id';
import { dateSerializer } from '@api/common/json/date-serializer';

@jsonObject
export class EquipmentFilterCollectionRequest {
  @jsonMember name?: string;
  @jsonMember categoryId?: Id;
  @jsonMember manufacturer?: string;
  @jsonMember serialNumber?: string;
  @jsonMember({ serializer: dateSerializer() }) createdAtFrom?: Date;
  @jsonMember({ serializer: dateSerializer() }) createdAtTo?: Date;
}
