import { jsonMember, jsonObject } from 'typedjson';
import { Id } from '@shared/types/id';
import { EquipmentCategoryResponse } from '@api/equipment/responses/equipment-category.response';

@jsonObject
export class EquipmentCollectionItemResponse {
  @jsonMember readonly id!: Id;
  @jsonMember readonly name!: string;
  @jsonMember readonly category!: EquipmentCategoryResponse;
  @jsonMember readonly createdAt!: Date;
  @jsonMember readonly manufacturer?: string;
  @jsonMember readonly serialNumber?: string;
  @jsonMember readonly purchasedAt?: Date;
  @jsonMember readonly productionYear?: Date;
}
