import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiHelperService } from '@api/common/services/api-helper.service';
import { ApiUrlFactory } from '@api/common/factories/api-url.factory';
import { CollectionRequest } from '@api/common/requests/collection.request';
import { map, Observable } from 'rxjs';
import { CollectionResponse } from '@api/common/responses/collection.response';
import { Id } from '@shared/types/id';
import { ApiParams } from '@environments/enums/api-params';
import { CreateResourceResponse } from '@api/common/responses/create-recource.response';
import { EquipmentFilterCollectionRequest } from '@api/equipment/filters/equipment-filter-collection.request';
import { EquipmentCollectionItemResponse } from '@api/equipment/responses/equipment-collection-item.response';
import { EquipmentDetailsResponse } from '@api/equipment/responses/equipment-details.response';
import { EquipmentCreateRequest } from '@api/equipment/requests/equipment-create.request';
import { environment } from '@environments/environment';
import { EquipmentUpdateRequest } from '@api/equipment/requests/equipment-update.request';

const { EQUIPMENT } = environment.api;

@Injectable({
  providedIn: 'root',
})
export class EquipmentApiService {
  constructor(
    private readonly http: HttpClient,
    private readonly helper: ApiHelperService,
    private readonly urlFactory: ApiUrlFactory
  ) {}

  collection(
    request: CollectionRequest<EquipmentFilterCollectionRequest>
  ): Observable<CollectionResponse<EquipmentCollectionItemResponse>> {
    const { LIST, HOST } = EQUIPMENT;
    const url = this.urlFactory.withQueryParams(
      HOST,
      LIST,
      this.helper.collectionRequestToQueryParams({
        request,
        filterRequestType: EquipmentFilterCollectionRequest,
      })
    );

    return this.http
      .get(url)
      .pipe(
        map(
          this.helper.bodyToCollectionResponse(EquipmentCollectionItemResponse)
        )
      );
  }

  details(id: Id): Observable<EquipmentDetailsResponse> {
    const { DETAILS, HOST } = EQUIPMENT;
    const url = this.urlFactory.withParams(HOST, DETAILS, {
      [ApiParams.EquipmentId]: String(id),
    });

    return this.http
      .get(url)
      .pipe(map(this.helper.bodyToResponse(EquipmentDetailsResponse)));
  }

  create(request: EquipmentCreateRequest): Observable<CreateResourceResponse> {
    const { CREATE, HOST } = EQUIPMENT;
    const url = this.urlFactory.simple(HOST, CREATE);
    const body = this.helper.requestToBody(request, EquipmentCreateRequest);

    return this.http
      .post(url, body)
      .pipe(map(this.helper.bodyToResponse(CreateResourceResponse)));
  }

  update(id: Id, request: EquipmentUpdateRequest): Observable<void> {
    const { UPDATE, HOST } = EQUIPMENT;
    const url = this.urlFactory.withParams(HOST, UPDATE, {
      [ApiParams.EquipmentId]: String(id),
    });
    const body = this.helper.requestToBody(request, EquipmentUpdateRequest);

    return this.http.put<void>(url, body);
  }
}
